// import { faCentercode } from '@fortawesome/free-brands-svg-icons'
import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { HashLink } from 'react-router-hash-link'
import { styler, rem, colors, breakpoints } from 'theme'

const styles = styler({
  container: {
    flexDirection: 'row',
    marginBottom: 0,
    [breakpoints.mobile]: {
      flexDirection: 'column',
    },
  },
  content: {
    flex: '1 !important',
    marginBottom: 'auto !important',
    marginTop: 'auto !important',
    [breakpoints.mobile]: {
      fontSize: 12,
    },
  },
  link: {
    color: `${colors.green} !important`,
  },
})

const Consent = () => (
  <CookieConsent
    location="bottom"
    buttonText="OK"
    cookieName="cookie-consent"
    style={{ background: colors.alphaDarkGray }}
    buttonStyle={rem({
      color: colors.darkGray,
      fontSize: 14,
      borderRadius: 5,
      minWidth: 120,
      [breakpoints.mobile]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
    })}
    containerClasses={`alert alert-warning col-lg-12 ${styles.container}`}
    contentClasses={styles.content}
    expires={150}
  >
    We use cookies to give you the best experience on our site. Cookies are data
    stored in your browser and are used by most websites to help personalize
    your web experience. By using this site, you agree to our{' '}
    <a href="/terms" className={styles.link}>
      Terms of Use
    </a>{' '}
    and{' '}
    <HashLink smooth to="/privacy#section3" className={styles.link}>
      Use of Cookies
    </HashLink>{' '}
    for performance, functionality, and other purposes as outlined in our
    privacy policy. For more information on how we process your personal data,
    please click{' '}
    <a href="/privacy" className={styles.link}>
      here
    </a>
    .
  </CookieConsent>
)

Consent.propTypes = {}
Consent.defaultProps = {}

export default Consent
