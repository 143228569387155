import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import 'firebase/compat/firestore'
import 'firebase/compat/performance'
import 'firebase/compat/analytics'
import 'firebase/compat/functions'
import config from './config'

firebase.initializeApp(config.firebase)
firebase.analytics()

export const auth = firebase.auth()
export const storage = firebase.storage().ref()
export const functions = firebase.functions()
export const firestore = firebase.firestore()
export const performance = firebase.performance()
export const fieldPathDocumentId = firebase.firestore.FieldPath.documentId()

function useEmulator(namespace, emulatorHost) {
  if (emulatorHost) {
    const [host, port] = emulatorHost.split(':')
    namespace.useEmulator(host, port)
  }
}
useEmulator(functions, config.firebaseEmulator.functionsHost)
useEmulator(firestore, config.firebaseEmulator.firestoreHost)

export default firebase
