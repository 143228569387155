import React, { Component } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import store from 'utils/store'
import { loadFontIcons } from 'components/FontIcon'
import { authenticate, storeAppState } from 'modules/app.module'
import config from 'utils/config'
import ToothboothApiProvider from 'api/chart/ToothboothApiProvider'
import Router from './routes'

const stripePromise = loadStripe(config.stripe.apiKey)

class App extends Component {
  componentDidMount() {
    store.dispatch(authenticate())
    store.dispatch(storeAppState())
    loadFontIcons()
  }

  render() {
    return (
      <Elements stripe={stripePromise}>
        <Provider store={store}>
          <ToothboothApiProvider>
            <Router />
            <ToastContainer theme="colored" position="bottom-right" />
          </ToothboothApiProvider>
        </Provider>
      </Elements>
    )
  }
}

export default App
