import { auth, firestore, functions } from 'utils/firebase'
import db from 'utils/firestore'

// ------------------------------------
// Constants
// ------------------------------------

const SAVE_ME = 'SAVE_ME'
const UPDATE_ME = 'UPDATE_ME'
const LOGGED_IN = 'LOGGED_IN'
const UPDATE_APP_STATE = 'UPDATE_APP_STATE'
const REQUIRE_WELCOME = 'REQUIRE_WELCOME'

const initialState = {
  checked: false,
  loggedIn: false,
  freePeriod: false,
  requireInviteCode: false,
  requireWelcome: false,
  me: {},
}

// ------------------------------------
// Actions
// ------------------------------------

export const authenticate = () => (dispatch) => {
  auth.onAuthStateChanged(async (me) => {
    if (!me) {
      return dispatch({
        type: LOGGED_IN,
        loggedIn: false,
        checked: true,
        me: {},
      })
    }

    // get user info
    const user = await db.findById('users', me?.uid)
    const enterprise_user = await db.findOne('enterprise_customers', {
      admin_users: {
        operation: 'array-contains',
        value: me?.uid,
      },
    })

    let _isEnterpriseUser = false
    let _enterprise_id = null

    if (enterprise_user) {
      _isEnterpriseUser = true
      _enterprise_id = enterprise_user.id
    }

    // login
    return dispatch({
      type: LOGGED_IN,
      loggedIn: true,
      checked: true,
      me: {
        ...me,
        ...user,
        uid: me?.uid,
        emailVerified: me?.emailVerified,
        isEnterpriseUser: _isEnterpriseUser,
        enterprise_id: _enterprise_id,
      },
    })
  })
}

export const storeAppState = () => async (dispatch) => {
  const res = await firestore.collection('app_state').doc('app').get()
  if (res.exists) {
    dispatch({
      type: UPDATE_APP_STATE,
      ...res.data(),
    })
  }
}

const joinWaitList =
  ({ firstName, lastName, country, email }) =>
  () =>
    new Promise(async (resolve, reject) => {
      try {
        const collectionName = 'join_wait_list'
        const emailLower = email.toLowerCase()
        
        // store data in firestore
        await db.create(collectionName, {
          firstName,
          lastName,
          country: country.value,
          email: emailLower,
        })
        resolve()
      } catch (err) {
        if (err.code === 'internal' || err.code === 'deadline-exceeded')
          reject(
            new Error(
              'Request timed out.  Please check your network connection and try again.',
            ),
          )
        else reject(err)
      }
    })

const signup =
  ({
    fullName,
    company,
    country,
    email,
    phone,
    newsLetter,
    password,
    inviteCode,
  }) =>
  (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        if (inviteCode) {
          // check invite code
          const {
            data: { isValid },
          } = await functions.httpsCallable('app-checkInviteCode')({
            inviteCode: inviteCode.toUpperCase(), // invite code check shouldn’t be case sensitive
          })

          if (!isValid) {
            reject(
              new Error(
                'The invite code you are using is not valid. Please try it later or contact to support.',
              ),
            )
            return
          }
        }

        // create user
        const { user } = await auth.createUserWithEmailAndPassword(
          email,
          password,
        )

        // send confirmation email
        await user.sendEmailVerification()

        // store data in firestore
        await db.create(
          'users',
          {
            fullName,
            company,
            country: country.value,
            email,
            phone,
            newsLetter,
            credits: 0,
          },
          user.uid,
        )

        // consume invite code
        if (inviteCode) {
          await functions.httpsCallable('app-consumeInviteCode')({
            inviteCode: inviteCode.toUpperCase(), // invite code check shouldn’t be case sensitive
          })
        }

        // show welcome dialog
        dispatch({
          type: REQUIRE_WELCOME,
          requireWelcome: true,
        })

        // sign in
        dispatch(authenticate())

        resolve(user)
      } catch (err) {
        if (err.code === 'internal' || err.code === 'deadline-exceeded')
          reject(
            new Error(
              'Request timed out.  Please check your network connection and try again.',
            ),
          )
        else reject(err)
      }
    })

const login = (email, password) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const { user } = await auth.signInWithEmailAndPassword(email, password)
      if (!user) reject(new Error('Failed to login. please try it again later'))
      dispatch(authenticate())
      resolve(user)
    } catch (err) {
      reject(err)
    }
  })

const logout = () => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await auth.signOut()
      dispatch({
        type: SAVE_ME,
        me: {},
      })
      dispatch(authenticate())
      resolve()
    } catch (err) {
      reject(err)
    }
  })

const resetPassword = (email) => () =>
  new Promise(async (resolve, reject) => {
    try {
      await auth.sendPasswordResetEmail(email)
      resolve()
    } catch (err) {
      reject(err)
    }
  })

const updateMe = (uid, input) => async (dispatch) => {
  try {
    const user = await db.updateById('users', uid, input)
    dispatch({ type: UPDATE_ME, user })
    return new Promise((resolve) => {
      resolve(user)
    })
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

export const actions = {
  authenticate,
  storeAppState,
  updateMe,
  signup,
  joinWaitList,
  login,
  logout,
  resetPassword,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOGGED_IN]: (state, { loggedIn, me, checked }) => ({
    ...state,
    loggedIn,
    me,
    checked,
  }),
  [SAVE_ME]: (state, { me }) => ({
    ...state,
    me,
  }),
  [UPDATE_ME]: (state, { user }) => ({
    ...state,
    me: {
      ...state.me,
      ...user,
    },
  }),
  [UPDATE_APP_STATE]: (state, { freePeriod, requireInviteCode }) => ({
    ...state,
    freePeriod,
    requireInviteCode,
  }),
  [REQUIRE_WELCOME]: (state, { requireWelcome }) => ({
    ...state,
    requireWelcome,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
