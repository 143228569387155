import { functions } from 'utils/firebase'
import { SimulationSteps } from 'utils/const'
import Compress from 'compress.js'

import axios from 'axios'

// ------------------------------------
// Constants
// ------------------------------------
const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
const SET_ORIGIN_PHOTO_DATA = 'SET_ORIGIN_PHOTO_DATA'
const SET_SIMULATION_RESULTS = 'SET_SIMULATION_RESULTS'
const SET_SIMULATION_MODE = 'SET_SIMULATION_MODE'
const SET_IMAGE_MAX_WIDTH = 'SET_IMAGE_MAX_WIDTH'
const SET_CLIENT_DATA = 'SET_CLIENT_DATA'
const FORMAT_CLIENT_DATA = 'FORMAT_CLIENT_DATA'
const SET_LOADING = 'SET_LOADING'
const SAVE_DOCID = 'SAVE_DOCID'

const initialState = {
  file_content: {},
  description: '',
  form_data: '',
  preview_img: '',
  current_step: 'Landing',
  simulation_results: {},
  client_data: {
    client_id: null,
    client_secret: null,
  },
  disabled: false,
  redirect_url: '',
  clientDisplayName: '',
  template_name: '',
  image_max_width: 2000,
  loading: true,
  error: null,
  doc_id: '',
}

const setCurrentStep = (step) => (dispatch) =>
  dispatch({
    type: SET_CURRENT_STEP,
    currentStep: step,
  })

const setSimulationMode = (_mode) => (dispatch) =>
  dispatch({
    type: SET_SIMULATION_MODE,
    mode: _mode,
  })

const setImageMaxWidth = (_width) => (dispatch) =>
  dispatch({
    type: SET_IMAGE_MAX_WIDTH,
    width: _width,
  })

const setSimulationResults = (results) => (dispatch) =>
  dispatch({
    type: SET_SIMULATION_RESULTS,
    simulationResults: results,
  })

const formatClientData = () => (dispatch) => {
  dispatch({
    type: FORMAT_CLIENT_DATA,
  })
}

const setLoading = (loading) => ({
  type: SET_LOADING,
  _loading: loading,
})

const resizeImageFn = async (file, image_max_width) => {
  const compress = new Compress()
  const resizedImage = await compress.compress([file], {
    size: 2, // the max size in MB, defaults to 2MB
    quality: 1, // the quality of the image, max is 1,
    maxWidth: image_max_width, // the max width of the output image, defaults to 1920px
    maxHeight: image_max_width, // the max height of the output image, defaults to 1920px
    resize: true, // defaults to true, set false if you do not want to resize the image width and height
  })
  const img = resizedImage[0]
  const base64str = img.data
  const imgExt = img.ext
  const resizedFile = Compress.convertBase64ToFile(base64str, imgExt)
  return resizedFile
}

const getTBWLandingPageMeta = (customUrl) => async (dispatch) => {
  try {
    dispatch(setLoading(true))
    const res = await functions.httpsCallable('tbw-getLandingPageMeta')({
      customUrl,
    })
    if (res) {
      dispatch(setLoading(false))
    }
    return dispatch({
      type: SET_CLIENT_DATA,
      clientData: res.data,
    })
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

export const onTakePhoto = (e, image_max_width) => async (dispatch) => {
  const selectedFile = e.target.files[0]
  if (selectedFile) {
    const preprocessedFile = await resizeImageFn(selectedFile, image_max_width)

    const formData = new FormData()
    formData.append('imgPhoto', preprocessedFile, selectedFile.name)
    const imageUrl = URL.createObjectURL(preprocessedFile)

    const fileContent = await preprocessedFile.arrayBuffer()

    dispatch({
      type: SET_ORIGIN_PHOTO_DATA,
      originPhotoData: { formData, imageUrl, fileContent },
    })

    dispatch(setCurrentStep(SimulationSteps.review))
  } else {
    console.error('No photo was taken')
  }
}

const processSubmittedLead = async ({
  id,
  email,
  phone,
  firstName,
  lastName,
  postalCode,
  concat_image,
  simulation_mode,
  simulation_id,
  marketingConsent,
  lead_email_base_subject,
  lead_form_secondary_url,
  lead_email_subject_prefix,
  showModeAndMarketingConsent,
  is_lead_email_base_subject_prefix_required,
  ...queryParams
}) => {
  try {
    const res = await functions.httpsCallable('tbw-processSubmittedLead')({
      id,
      email,
      concat_image,
      simulation_mode,
      simulation_id,
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      lead_email_base_subject,
      postal_code: postalCode,
      lead_email_subject_prefix,
      is_lead_email_base_subject_prefix_required,
      marketing_consent: marketingConsent,
      show_mode_and_marketing_consent: showModeAndMarketingConsent,
      ...queryParams,
    })

    if (lead_form_secondary_url) {
      try {
        await axios.get(lead_form_secondary_url, {
          params: {
            firstName: encodeURIComponent(firstName),
            lastName: encodeURIComponent(lastName),
            email: encodeURIComponent(email),
            phone: encodeURIComponent(phone),
          },
        })
      } catch (err) {
        console.error(`Error sending lead to ${lead_form_secondary_url}`, err)
      }
    }

    return res
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

export const actions = {
  setCurrentStep,
  setImageMaxWidth,
  formatClientData,
  setSimulationMode,
  setSimulationResults,
  getTBWLandingPageMeta,
  processSubmittedLead,
  onTakePhoto,
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_CURRENT_STEP]: (state, { currentStep }) => ({
    ...state,
    current_step: currentStep,
  }),
  [SET_ORIGIN_PHOTO_DATA]: (state, { originPhotoData }) => ({
    ...state,
    form_data: originPhotoData.formData,
    preview_img: originPhotoData.imageUrl,
    file_content: originPhotoData.fileContent,
  }),
  [SET_SIMULATION_MODE]: (state, { mode }) => ({
    ...state,
    simulation_mode: mode,
  }),
  [SET_IMAGE_MAX_WIDTH]: (state, { width }) => ({
    ...state,
    image_max_width: width,
  }),
  [SET_SIMULATION_RESULTS]: (state, { simulationResults }) => ({
    ...state,
    simulation_results: simulationResults,
  }),
  [SET_LOADING]: (state, { _loading }) => ({
    ...state,
    loading: _loading,
  }),
  [SET_CLIENT_DATA]: (state, { clientData }) => ({
    ...state,
    client_data: {
      ...state.client_data,
      client_id: clientData.client_id,
      client_secret: clientData.client_secret,
    },
    error: clientData.error,
    doc_id: clientData.doc_id,
    disabled: clientData.disabled,
    description: clientData.description,
    redirect_url: clientData.redirect_url,
    template_name: clientData.template_name,
    clientDisplayName: clientData.display_name || '',
  }),
  [FORMAT_CLIENT_DATA]: (state) => ({
    ...state,
    client_data: {
      ...state.client_data,
      client_id: null,
      client_secret: null,
    },
  }),
  [SAVE_DOCID]: (state, { docId }) => ({
    ...state,
    doc_id: docId,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
