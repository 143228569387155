import React from 'react'
import { PropTypes } from 'prop-types'
import { Route } from 'react-router-dom'
// import { GENERAL_PATH } from 'utils/const'

export const AdminRoute = ({ route_path, component, isAuthenticated }) => {
  return isAuthenticated ? (
    <Route exact path={route_path} component={component} />
  ) : null
}

AdminRoute.propTypes = {
  route_path: PropTypes.string,
  component: PropTypes.elementType,
  isAuthenticated: PropTypes.bool,
}

AdminRoute.defaultProps = {
  route_path: '',
  component: null,
  isAuthenticated: false,
}
