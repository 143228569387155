// ------------------------------------
// Constants
// ------------------------------------

const UPDATE_SELECTED_TIER = 'UPDATE_SELECTED_TIER'
const UPDATE_SMILE_PHOTO = 'UPDATE_SMILE_PHOTO'
const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION'
const UPDATE_COUNTY_PROVINCE = 'UPDATE_COUNTY_PROVINCE'
const UPDATE_PAYMENT_INFO = 'UPDATE_PAYMENT_INFO'
const UPDATE_PAYMENT_METHOD_ID = 'UPDATE_PAYMENT_METHOD_ID'
const RESET_ORDER = 'RESET_ORDER'

const initialState = {
  // step 1: select tier
  tier: 'regular',
  // step 2: select smile photo
  photo: null,
  description: '',
  // step 3: select country/province
  country: null,
  province: null,
  saveCard: false,
  paymentMethodId: null,
  // step 4: Confirmation
  currency: 'CAD',
  clientSecret: null,
  card: null,
}

// ------------------------------------
// Actions
// ------------------------------------

const updateSelectedTier = (tier) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      dispatch({
        type: UPDATE_SELECTED_TIER,
        tier,
      })

      resolve()
    } catch (err) {
      reject(err)
    }
  })

const updateSmilePhoto = (file) => (dispatch) =>
  dispatch({
    type: UPDATE_SMILE_PHOTO,
    photo: file,
  })

const updateDescription = (description) => (dispatch) =>
  dispatch({
    type: UPDATE_DESCRIPTION,
    description,
  })

const updateCountryProvince =
  ({ country, province }) =>
  (dispatch) =>
    dispatch({
      type: UPDATE_COUNTY_PROVINCE,
      country,
      province,
    })

const updatePaymentInfo =
  ({ currency, clientSecret, card, saveCard }) =>
  (dispatch) =>
    dispatch({
      type: UPDATE_PAYMENT_INFO,
      currency,
      clientSecret,
      card,
      saveCard,
    })

const updatePaymentMethodId = (paymentMethodId) => (dispatch) =>
  dispatch({
    type: UPDATE_PAYMENT_METHOD_ID,
    paymentMethodId,
  })

const resetOrder = () => (dispatch) =>
  dispatch({
    type: RESET_ORDER,
  })

export const actions = {
  updateSelectedTier,
  updateSmilePhoto,
  updateDescription,
  updateCountryProvince,
  updatePaymentInfo,
  updatePaymentMethodId,
  resetOrder,
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [UPDATE_SELECTED_TIER]: (state, { tier, clientSecret }) => ({
    ...state,
    tier,
    clientSecret,
  }),
  [UPDATE_SMILE_PHOTO]: (state, { photo }) => ({
    ...state,
    photo,
  }),
  [UPDATE_DESCRIPTION]: (state, { description }) => ({
    ...state,
    description,
  }),
  [UPDATE_COUNTY_PROVINCE]: (state, { country, province }) => ({
    ...state,
    country,
    province,
  }),
  [UPDATE_PAYMENT_METHOD_ID]: (state, { paymentMethodId }) => ({
    ...state,
    paymentMethodId,
  }),
  [UPDATE_PAYMENT_INFO]: (
    state,
    { currency, clientSecret, card, saveCard },
  ) => ({
    ...state,
    currency,
    clientSecret,
    card,
    saveCard,
  }),
  [RESET_ORDER]: () => ({ ...initialState }),
}

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
