import React, { useEffect } from 'react'
import Loadable from 'react-loadable'
import CookieConsent from 'components/CookieConsent'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { GENERAL_PATH, ADMIN_PATH } from 'utils/const'
import Connector from 'utils/connector'
import { colors } from 'theme'
import { AdminRoute } from 'Layouts/AppRouting'

const Ondemand = Loadable({
  loader: () => import('./pages/Ondemand'),
  loading: () => <div />,
})

const ToothBooth = Loadable({
  loader: () => import('./pages/ToothBooth'),
  loading: () => <div />,
})

const Terms = Loadable({
  loader: () => import('./pages/Terms'),
  loading: () => <div />,
})

const TermsBR = Loadable({
  loader: () => import('./pages/TermsBR'),
  loading: () => <div />,
})

const Privacy = Loadable({
  loader: () => import('./pages/Privacy'),
  loading: () => <div />,
})

const PrivacyBR = Loadable({
  loader: () => import('./pages/PrivacyBR'),
  loading: () => <div />,
})

const PhotoGuide = Loadable({
  loader: () => import('./pages/PhotoGuide'),
  loading: () => <div />,
})

const Dashboard = Loadable({
  loader: () => import('./pages/Dashboard'),
  loading: () => <div />,
})

const Account = Loadable({
  loader: () => import('./pages/Account'),
  loading: () => <div />,
})

const EnterpriseDashboard = Loadable({
  loader: () => import('./pages/Enterprise'),
  loading: () => <div />,
})

const Simulation = Loadable({
  loader: () => import('./templates'),
  loading: () => <div />,
})

const Router = ({ checked, loggedIn }) => {
  const shouldDisplayConsent = !window.location.pathname.includes(
    GENERAL_PATH.simulation.replace(':id', ''),
  )
  // update css variables
  useEffect(() => {
    Object.keys(colors).forEach((key) => {
      const cssKey = `--${key}`
      const cssVal = colors[key]
      document.body.style.setProperty(cssKey, cssVal)
    })
  }, [])

  if (!checked) return <aside>Loading...</aside>

  return (
    <BrowserRouter>
      <Switch>
        {/* General Routes */}
        <Route exact path={GENERAL_PATH.home} component={ToothBooth} />
        <Route exact path={GENERAL_PATH.terms} component={Terms} />
        <Route exact path={GENERAL_PATH.termsBR} component={TermsBR} />
        <Route exact path={GENERAL_PATH.privacy} component={Privacy} />
        <Route exact path={GENERAL_PATH.privacyBR} component={PrivacyBR} />
        <Route exact path={GENERAL_PATH.guide} component={PhotoGuide} />
        <Route exact path={GENERAL_PATH.ondemand} component={Ondemand} />
        <Route exact path={GENERAL_PATH.simulation} component={Simulation} />
        {/* Admin Routes */}
        <AdminRoute
          path={ADMIN_PATH.account}
          component={Account}
          isAuthenticated={loggedIn}
        />
        <AdminRoute
          path={ADMIN_PATH.dashboard}
          component={Dashboard}
          isAuthenticated={loggedIn}
        />
        <AdminRoute
          path={ADMIN_PATH.enterprise}
          component={EnterpriseDashboard}
          isAuthenticated={loggedIn}
        />
        <Redirect to={loggedIn ? ADMIN_PATH.account : GENERAL_PATH.home} />
      </Switch>
      {shouldDisplayConsent && <CookieConsent />}
    </BrowserRouter>
  )
}

Router.propTypes = {
  checked: PropTypes.bool,
  loggedIn: PropTypes.bool,
}

Router.defaultProps = {
  checked: false,
  loggedIn: false,
}

export default (props) => (
  <Connector>
    {({
      state: {
        app: { loggedIn, checked },
      },
    }) => <Router checked={checked} loggedIn={loggedIn} {...props} />}
  </Connector>
)
