import { firestore, fieldPathDocumentId } from 'utils/firebase'
import db from 'utils/firestore'

// ------------------------------------
// Constants
// ------------------------------------

const SET_SELECTED_ORDER = 'SET_SELECTED_ORDER'
const TOGGLE_ORDER_DETAILS = 'TOGGLE_ORDER_DETAILS'

const initialState = {
  selectedOrder: {
    order: {},
    smile: {},
    status: {},
  },
  isOpenOrderDetails: false,
}

const defaultOrderStateTypes = [
  {
    name: 'pending',
    status_color: 'gray',
    status_icon_name: 'clock',
    user_display_string: 'Queued for Processing',
  },
  {
    name: 'photo_composition_issue',
    status_color: 'yellow',
    status_icon_name: 'exclamation-triangle',
    user_display_string: 'Photo Standards Not Met',
  },
  {
    name: 'photo_lighting_issue',
    status_color: 'yellow',
    status_icon_name: 'exclamation-triangle',
    user_display_string: 'Photo Standards Not Met',
  },
  {
    name: 'photo_blurry_issue',
    status_color: 'yellow',
    status_icon_name: 'exclamation-triangle',
    user_display_string: 'Photo Standards Not Met',
  },
  {
    name: 'photo_teeth_visibility_issue',
    status_color: 'yellow',
    status_icon_name: 'exclamation-triangle',
    user_display_string: 'Photo Standards Not Met',
  },
  {
    name: 'photo_head_turned_issue',
    status_color: 'yellow',
    status_icon_name: 'exclamation-triangle',
    user_display_string: 'Photo Standards Not Met',
  },
  {
    name: 'simulation_failed_issue',
    status_color: 'yellow',
    status_icon_name: 'exclamation-triangle',
    user_display_string: 'Simulation Standards Not Met',
  },
  {
    name: 'ready_for_review',
    status_color: 'gray',
    status_icon_name: 'clock',
    user_display_string: 'Submitted',
  },
  {
    name: 'completed',
    status_color: 'green',
    status_icon_name: 'check-circle',
    user_display_string: 'Ready for Download',
  },
]

// ------------------------------------
// Helpers
// ------------------------------------

/**
 * get smile task based on smile task id in order
 * @param {object} order
 * @param {array} orderStatusTypes
 */
const getSmileTask = (order, orderStatusTypes) =>
  new Promise(async (resolve) => {
    try {
      const { smileTaskId } = order
      const { docs } = await firestore
        .collection('smile_tasks')
        .where(fieldPathDocumentId, '==', smileTaskId)
        .get()

      // check if smile task exists or not
      const isValid = Array.isArray(docs) && docs.length > 0 && docs[0].exists

      // decode smile task if exists
      const smile = isValid ? docs[0].data() : {}

      // map order status type
      const statusType = orderStatusTypes.filter(
        ({ name }) => name === order?.status,
      )
      const status = statusType.length > 0 ? statusType[0] : {}

      resolve({
        order,
        smile,
        status,
      })
    } catch (err) {
      resolve({
        order,
        smile: {},
        status: {},
      })
    }
  })

/**
 *
 * @returns get all users from join_wait_list collection
 */
const getToothBoothCustomUrls = (id) => {
  try {
    return db.findMany('toothbooth_web_pages', {
      enterprise_id: {
        operation: '==',
        value: id,
      },
    })
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

const getCurrentEnterpriseInfo = async (enterprise_id) => {
  try {
    const enterpriseUser = await db.findById(
      'enterprise_customers',
      enterprise_id,
    )
    if (!enterpriseUser.site_templates) return null
    return {
      template_list: enterpriseUser.site_templates,
      default_redirect_url: enterpriseUser.default_redirect_url,
    }
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

const getHistory = async (id) => {
  try {
    const historyCollectionRef = firestore
      .collection('toothbooth_web_pages')
      .doc(id)
      .collection('history')

    const querySnapshot = await historyCollectionRef.get()
    // Initialize an array to store the data of all documents
    const historyData = []

    querySnapshot.forEach((doc) => {
      // Extract the data of each document and push it to the array
      historyData.push(doc.data())
    })

    return historyData
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

const addToothBoothCustomUrl = async ({
  disabled,
  description,
  display_name,
  redirect_url,
  enterprise_id,
  template_name,
  destination_email,
}) => {
  try {
    const data = {
      disabled,
      description,
      display_name,
      redirect_url,
      template_name,
      enterprise_id,
      destination_email,
    }

    const ref = firestore.collection('toothbooth_web_pages').doc()
    // create record
    await ref.set({
      ...data,
      id: ref.id,
      custom_url: `/r/${ref.id}`,
      createdAt: new Date(),
      updatedAt: new Date(),
      enable_lead_email: true,
      enable_daily_alltime_summary: false,
      enable_daily_lead_summary: false,
      attach_simulation_to_lead: false,
    })

    return (
      await firestore.collection('toothbooth_web_pages').doc(ref.id).get()
    ).data()
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

const updateToothBoothCustomUrl = (uid, data) => {
  try {
    return db.updateById('toothbooth_web_pages', uid, data)
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

const deleteToothBoothCustomUrl = (id) => {
  try {
    return db.deleteById('toothbooth_web_pages', id)
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

/**
 * get order status types
 */
const getOrderStatusTypes = () =>
  new Promise(async (resolve) => {
    try {
      const res = await firestore.collection('app_state').doc('app').get()
      if (res.exists) {
        const { order_state_types } = res.data()
        if (Array.isArray(order_state_types)) resolve(order_state_types)
        else resolve(defaultOrderStateTypes)
        return
      }
      resolve(defaultOrderStateTypes)
    } catch (err) {
      resolve(defaultOrderStateTypes)
    }
  })

// ------------------------------------
// Actions
// ------------------------------------

const setSelectedOrder = (order) => (dispatch) =>
  dispatch({
    type: SET_SELECTED_ORDER,
    order,
  })

const toggleOrderDetails = () => (dispatch) =>
  dispatch({
    type: TOGGLE_ORDER_DETAILS,
  })

/**
 * get orders
 * @param {string} uid
 */
const getOrders = (uid) => async () =>
  new Promise(async (resolve, reject) => {
    try {
      let items = []

      // get the user's orders
      const { docs } = await firestore
        .collection('on_demand_orders')
        .where('clientId', '==', uid)
        .get()

      if (Array.isArray(docs)) {
        // decode data
        const Orders = []
        docs.forEach((doc) => {
          if (doc.exists)
            Orders.push({
              documentId: doc.id,
              ...doc.data(),
            })
        })

        // get order status types
        const orderStatusTypes = await getOrderStatusTypes()

        // map with smile tasks
        items = await Promise.all(
          Orders.map((item) => getSmileTask(item, orderStatusTypes)),
        )
      }
      resolve(items)
    } catch (err) {
      reject(err)
    }
  })

const updateOrderDescription = (docId, description) => () =>
  new Promise(async (resolve, reject) => {
    try {
      // get the user's orders
      await firestore
        .collection('on_demand_orders')
        .doc(docId)
        .update({ description })
      resolve()
    } catch (err) {
      reject(err)
    }
  })

export const actions = {
  setSelectedOrder,
  toggleOrderDetails,
  getOrders,
  getHistory,
  updateOrderDescription,
  getToothBoothCustomUrls,
  addToothBoothCustomUrl,
  updateToothBoothCustomUrl,
  deleteToothBoothCustomUrl,
  getCurrentEnterpriseInfo,
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [SET_SELECTED_ORDER]: (state, { order }) => ({
    ...state,
    selectedOrder: order,
  }),
  [TOGGLE_ORDER_DETAILS]: (state) => ({
    ...state,
    isOpenOrderDetails: !state.isOpenOrderDetails,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
