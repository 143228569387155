import React from 'react'
import { Provider } from 'urql'
import { PropTypes } from 'prop-types'
import { client } from './toothboothApi'

function ToothboothApiProvider({ children }) {
  return <Provider value={client}>{children}</Provider>
}

ToothboothApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ToothboothApiProvider
