import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSquare,
  faArrowAltCircleUp,
} from '@fortawesome/free-regular-svg-icons'
import {
  faTimes,
  faSmile,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faAngleUp,
  faAngleDown,
  faChevronUp,
  faChevronDown,
  faCheckSquare,
  faExclamationTriangle,
  faEnvelopeOpenText,
  faPaperPlane,
  faCheckCircle,
  faRobot,
  faDollarSign,
  faTachometerAlt,
  faPhone,
  faEnvelope,
  faInfo,
  faPen,
  faClock,
  faExchangeAlt,
  faSearchMinus,
  faCoins,
  faArrowLeft,
  faTrash,
  faRocket,
  faTimesCircle,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebookSquare,
  faTwitterSquare,
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons'

// TODO: add icons here...
export const loadFontIcons = () =>
  library.add(
    // regular
    faSquare,

    // solid
    faTimes,
    faSmile,
    faArrowRight,
    faArrowUp,
    faArrowDown,
    faAngleUp,
    faAngleDown,
    faChevronUp,
    faChevronDown,
    faArrowAltCircleUp,
    faCheckSquare,
    faExclamationTriangle,
    faEnvelopeOpenText,
    faPaperPlane,
    faCheckCircle,
    faRobot,
    faDollarSign,
    faTachometerAlt,
    faPhone,
    faEnvelope,
    faInfo,
    faPen,
    faClock,
    faExchangeAlt,
    faSearchMinus,
    faCoins,
    faArrowLeft,
    faTrash,
    faRocket,
    faTimesCircle,
    faShoppingCart,

    // brand
    faFacebookSquare,
    faTwitterSquare,
    faInstagramSquare,
  )

const FontIcon = ({ name, className, style }) => (
  <FontAwesomeIcon icon={name} className={className} style={style} />
)

FontIcon.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  className: PropTypes.string,
  style: PropTypes.shape({}),
}

FontIcon.defaultProps = {
  name: 'times',
  className: '',
  style: {},
}

export default FontIcon
