import { firestore } from './firebase'

/**
 * create record under specified collection name
 * @param {string} name
 * @param {object} input
 * @param {id} string Optional document ID to create with
 * @returns
 */
const create = (name, input, id) =>
  new Promise(async (resolve, reject) => {
    try {
      // collection reference
      const ref = id
        ? await firestore.collection(name).doc(id)
        : await firestore.collection(name).doc()

      // create record
      const docData = {
        ...input,
        id: ref.id,
        createdAt: new Date(),
        updatedAt: new Date(),
      }

      await ref.set(docData)

      resolve(docData)
    } catch (err) {
      reject(err)
    }
  })

/**
 * create many records
 * @param {string} name
 * @param {array} inputs
 * @returns
 */
const createMany = (name, inputs) =>
  Promise.all(inputs.map((input) => create(name, input, input?.id)))

/**
 * find record under specified collection name
 * @param {string} name
 * @param {string} id
 * @returns
 */
const findById = (name, id) =>
  new Promise(async (resolve, reject) => {
    try {
      // get snapshot
      const snapshot = await firestore.collection(name).doc(id).get()

      // return data if exists
      resolve(snapshot.exists ? snapshot.data() : null)
    } catch (err) {
      reject(err)
    }
  })

/**
 * find records under specified collection name
 * @param {string} name
 * @param {object} where ex. { email: { operation: '==', value: "test@test.com" }, createdAt: { operation: '>', value: 12345678 } }
 * @param {object} order ex. { createdAt: 'desc', updatedAt: 'asc' }
 * @param {number} limit
 * @param {bool} snapshot
 * @returns
 */
const findMany = (name, where, order, limit, snapshot) =>
  new Promise(async (resolve, reject) => {
    try {
      let collection = firestore.collection(name)

      // where conditions
      if (where) {
        Object.keys(where).forEach((key) => {
          const { operation, value } = where[key]
          collection = collection.where(key, operation, value)
        })
      }

      // order conditions
      if (order) {
        Object.keys(order).forEach((key) => {
          const value = where[key]
          collection = collection.orderBy(key, value)
        })
      }

      // limit
      if (typeof limit === 'number') {
        collection = collection.limit(limit)
      }

      const snapshots = await collection.get()
      resolve(snapshot ? snapshots : snapshots.docs.map((doc) => doc.data()))
    } catch (err) {
      reject(err)
    }
  })

/**
 * find a record under specified collection name
 * @param {string} name
 * @param {object} where ex. { email: { operation: '==', value: "test@test.com" }, createdAt: { operation: '>', value: 12345678 } }
 * @param {object} order ex. { createdAt: 'desc', updatedAt: 'asc' }
 * @returns
 */
const findOne = async (name, where, order) => {
  try {
    const records = await findMany(name, where, order, 1)
    return new Promise((resolve) => {
      resolve(records?.[0])
    })
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

/**
 * Update a record by filter under specified collection name
 * @param {string} name
 * @param {object} where ex. { email: { operation: '==', value: "test@test.com" }, createdAt: { operation: '>', value: 12345678 } }
 * @param {object} input
 * @returns
 */
const update = async (name, where, input) => {
  try {
    const doc = await findOne(name, where)
    if (!doc) {
      return new Promise((_, reject) => {
        reject(new Error('Document not found'))
      })
    }

    // update record
    await firestore
      .collection(name)
      .doc(doc.id)
      .update({
        ...input,
        updatedAt: new Date(),
      })

    // fetch id
    const record = await findById(name, doc.id)

    return new Promise((resolve) => {
      resolve(record)
    })
  } catch (err) {
    return new Promise((_, reject) => {
      reject(err)
    })
  }
}

/**
 * update a record by id under specified collection name
 * @param {string} name
 * @param {string} id
 * @returns
 */
const updateById = (name, id, input) =>
  new Promise(async (resolve, reject) => {
    try {
      // update record
      await firestore
        .collection(name)
        .doc(id)
        .update({
          ...input,
          updatedAt: new Date(),
        })

      // fetch id
      const record = await findById(name, id)

      resolve(record)
    } catch (err) {
      reject(err)
    }
  })

/**
 * update many records
 * @param {string} name
 * @param {array} inputs
 * @returns
 */
const updateMany = (name, inputs) =>
  Promise.all(inputs.map((input) => updateById(name, input?.id, input)))

/**
 * delete record under specified collection name
 * @param {string} name
 * @param {string} id
 * @returns
 */
const deleteById = (name, id) =>
  new Promise(async (resolve, reject) => {
    try {
      // update record
      await firestore.collection(name).doc(id).delete()

      resolve(true)
    } catch (err) {
      reject(err)
    }
  })

/**
 * delete records under specified collection name
 * @param {string} name
 * @param {object} where ex. { email: { operation: '==', value: "test@test.com" }, createdAt: { operation: '>', value: 12345678 } }
 * @param {object} order ex. { createdAt: 'desc', updatedAt: 'asc' }
 * @param {number} limit
 * @returns
 */
const deleteMany = (name, where, order, limit) =>
  new Promise(async (resolve, reject) => {
    try {
      const batch = firestore.batch()

      const snapshots = await findMany(name, where, order, limit, true)
      snapshots.forEach((snapshot) => {
        batch.delete(snapshot.ref)
      })

      // update record
      await batch.commit()

      resolve(true)
    } catch (err) {
      reject(err)
    }
  })

export default {
  create,
  createMany,
  findById,
  findMany,
  findOne,
  update,
  updateById,
  updateMany,
  deleteById,
  deleteMany,
}
